<template>
  <div class="selected-project">
    <div class="project-glance">
      <div class="address">
        <div>{{ project.name }}</div>
        <div>{{ project.location_street }}</div>
        <div>{{ project.location_city }},</div>
        <div>{{ project.location_state }}</div>
        <div>{{ project.location_country }} {{ project.location_zip }}</div>
        <div></div>
      </div>
      <div class="project-title">
        <h1 class="font-weight-light text-center">{{ project.name }}</h1>
        <router-link to="/">Choose Another Project</router-link>
      </div>
      <b-button @click="generateBmp" variant="success">
        Generate BMP
        <i
          :class="
            bmpGenerating ? 'fa fa-spinner fa-spin' : 'fa fa-arrow-circle-down'
          "
        ></i>
      </b-button>
    </div>
    <div class="nav-buttons">
      <NavButton color="yellow" text="Contacts" pageName="contacts" />
      <NavButton color="light-blue" text="Documents" pageName="documents" />
      <NavButton color="dark-blue" text="Firestop Systems" pageName="systems" />
    </div>
    <div :class="classList">
      <transition
        name="router-anim"
        mode="out-in"
        enter-active-class="animated fadeIn animation-duration"
        leave-active-class="animated fadeOut animation-duration"
      >
        <router-view
          @reloadData="loadData"
          :isAdmin="isAdmin"
          v-if="showChildren >= 2"
          :project="projectDetails"
          :projectName="project.name"
          :myId="myId"
          :reps="reps"
        />
      </transition>
    </div>
    <b-modal ok-only v-model="modalShow" id="email-sent" title="Success">
      <p class="my-4">{{ modalText }}</p>
    </b-modal>
  </div>
</template>

<script>
import NavButton from "@/components/NavButton";
import { get, post } from "@/utils/api";
import axios from "axios";
export default {
  props: ["projects", "myId"],
  components: {
    NavButton,
  },
  data() {
    return {
      projectDetails: {},
      showChildren: 0,
      reps: [],
      bmpGenerating: false,
      modalShow: false,
      modalText: "",
    };
  },
  computed: {
    project() {
      return this.projects.filter(
        (proj) => proj.slug == this.$route.params.slug
      )[0];
    },
    classList() {
      let classes = "content ";
      if (this.$route.path.indexOf("contacts") > -1) {
        return classes + "border-orange";
      } else if (this.$route.path.indexOf("documents") > -1)
        return classes + "border-light-blue";
      else if (this.$route.path.indexOf("systems") > -1)
        return classes + "border-dark-blue";
      else return classes + "border-white";
    },
    isAdmin() {
      let myRole;
      this.projectDetails.users.forEach((user) => {
        if (user.email === this.myId.email) myRole = user.role;
      });
      return myRole === "owner" || myRole === "admin";
    },
  },
  methods: {
    loadData() {
      get(`barrier-management/project/${this.project.id}`)
        .then(({ data }) => {
          this.projectDetails = data;
          this.$set(this.projectDetails, "details", this.project);
          this.showChildren++;
        })
        .catch((err) => err);
    },
    getReps() {
      axios
        .get(`${process.env.VUE_APP_ROOT_API}company-hierarchy/employees`, {
          params: {
            country: this.project.location_country,
            zip: this.project.location_zip,
            industries: ["AL", "CN", "CO", "CW", "EL", "HC", "IF", "IN"],
            role_classes: ["RM", "TM", "CP", "RMC", "RMHC", "RMI"],
          },
        })
        .then(({ data }) => {
          // remove duplicates
          this.reps = data.data.filter((rep, i) => {
            const repsAfterThis = data.data.slice(i + 1);
            const emailsAfterThis = repsAfterThis.map(({ email }) => email);
            return !emailsAfterThis.includes(rep.email);
          });
          this.showChildren++;
        })
        .catch((err) => err);
    },
    generateBmp() {
      this.bmpGenerating = true;
      post("barrier-management/report", { id: this.project.id })
        .then(() => {
          this.bmpGenerating = false;
          this.modalText =
            "Your BMP is generating and will be emailed to your shortly.";
          this.modalShow = true;
        })
        .catch(() => {
          this.bmpGenerating = false;
          this.modalText = "There was an error generating your report.";
          this.modalShow = true;
        });
    },
  },
  mounted() {
    this.$emit("updateProjId", this.project.id);
    this.loadData();
    this.getReps();
  },
};
</script>

<style lang="scss" scoped>
.project-glance {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 3rem;
}
.nav-buttons {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  margin: 10px 0 15px 0;
}
.project-title {
  text-align: center;
}
.content {
  border-top: 3px solid;
  border-bottom: 3px solid;
  width: 96.7%;
  margin: 0 auto;
}
.border-orange {
  border-color: #ffb71b;
}
.border-light-blue {
  border-color: #008fd4;
}
.border-dark-blue {
  border-color: #0063a1;
}
.border-white {
  border-color: #fff;
}
.animation-duration {
  animation-duration: 0.3s;
}

@media only screen and (max-width: 700px) {
  .nav-buttons {
    flex-direction: column;
    align-items: center;
    margin-bottom: 0;
  }
  .project-glance {
    flex-direction: column;
    margin: 10px 0 0 0;
    .project-title {
      order: 0;
      margin: 15px 0 25px 0;
    }
    .address {
      margin-top: 20px;
      order: 1;
      text-align: center;
    }
  }
}
</style>
