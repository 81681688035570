<template>
  <router-link :class="classList" :to="'/'+$route.params.slug + '/'+pageName">{{text}}</router-link>
</template>

<script>
export default {
  name: "NavButton",
  props: ["pageName", "text", "color"],
  computed: {
    tabSelected() {
      return (
        this.$route.path === "/" + this.$route.params.slug + "/" + this.pageName
      );
    },
    classList() {
      return this.color + " page-nav " + (this.tabSelected ? "selected" : "");
    }
  }
};
</script>

<style lang="scss" scoped>
a {
  text-decoration: none;
  color: white;
  font-size: 20px;
  font-weight: bold;
  padding: 8px;
  border-radius: 30px;
  width: 30%;
  text-align: center;
  transform: translateY(0);
  transition: all 0.5s ease-in-out;
}
a:hover {
  color: white;
  text-decoration: none;
  box-shadow: 0px 3px 15px rgba(0, 0, 0, 0.3);
}
a.yellow {
  background-color: #ffb71b;
}
a.light-blue {
  background-color: #008fd4;
}
a.dark-blue {
  background-color: #0063a1;
}
.selected {
  transform: translateY(16px);
  border-radius: 20px 20px 0 0;
  transition: all 0.5s ease-in-out;
}

@media only screen and (max-width: 700px) {
  a {
    min-width: 300px;
    // max-width: 90vw;
    margin: 3px;
    transition: none;
    transition: border-radius 0.5s ease-in;
  }
  .selected {
    transform: none;
    border-radius: 20px 20px 0 0;
    transition: width 0.5s ease-out, border-radius 0.5s ease-out;
    order: 3;
    margin-bottom: 0px;
    max-width: 97%;
    width: calc(97% - 1px);
  }
}
</style>
